import 'timezone-data';
import { importProvidersFrom } from "@angular/core";
import { AppComponent } from "./app/app.component";
import { registerLocaleData } from "@angular/common";
import localeFi from "@angular/common/locales/fi";
import { bootstrapApplication } from "@angular/platform-browser";
import { provideFrontendApina } from "./app/apina.config";
import { provideFrontendMaterialSettings } from "./app/material.config";
import { provideFrontendMapData } from "./app/map/pilotweb-map-data-provider.service";
import { provideFrontendStompConfiguration } from "./app/rx-stomp.config";
import { PilotwebOperatorTimesheetEndpoint, PilotwebReferenceDataEndpoint } from "apina-frontend";
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { PILOTWEB_VERSION } from "pilotweb-version";
import { pilotwebVersionInterceptor, provideCommonProviders, provideOAuthHandler, provideReferenceDataEndpoint, upgradeInterceptor } from 'common';
import { provideOperatorTimesheetEndpoint } from 'operator-timesheet';
import { TranslateModule } from "@ngx-translate/core";
import { FRONTEND_ROUTES } from "./app/app.routing";
import { provideRouter, withComponentInputBinding } from "@angular/router";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { FrontendOAuthHandler } from "./app/oauth/frontend-oauth-handler";
import { tokenInterceptor } from "./app/oauth/token-interceptor";

registerLocaleData(localeFi);

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(TranslateModule.forRoot()),

        // Angular configuration
        provideAnimationsAsync(),
        provideHttpClient(withInterceptors([
            tokenInterceptor,
            pilotwebVersionInterceptor("Pilotweb", PILOTWEB_VERSION),
            upgradeInterceptor,
        ])),
        provideRouter(FRONTEND_ROUTES,
            withComponentInputBinding()
        ),

        // Pilotweb-specific providers
        provideCommonProviders(),
        provideFrontendApina(),
        provideFrontendMapData(),
        provideFrontendMaterialSettings(),
        provideFrontendStompConfiguration(),
        provideOperatorTimesheetEndpoint(PilotwebOperatorTimesheetEndpoint),
        provideReferenceDataEndpoint(PilotwebReferenceDataEndpoint),
        provideOAuthHandler(FrontendOAuthHandler),
    ]
}).catch((err) => console.error(err));
